<template>
  <div>
    <!-- BM -->
    <font-awesome-icon
      class="nature-icon bm"
      icon="fa-light fa-star-sharp"
      v-if="nature == 'BM'"
      v-b-tooltip.hover.topleft="'BM'"
      variant="secondary"
    />
    <!-- CONTROLLER -->
    <font-awesome-icon
      class="nature-icon controller"
      icon="fa-light fa-circle-star"
      v-if="nature == 'CONTROLLER'"
      v-b-tooltip.hover.topleft="'CONTROLLER'"
      variant="secondary"
    />
    <!-- FIRM -->
    <font-awesome-icon
      class="nature-icon firm"
      icon="fa-light fa-hexagon"
      v-if="nature == 'FIRM'"
      v-b-tooltip.hover.topleft="'FIRM'"
      variant="secondary"
    />
    <!-- FIRM -->
    <div v-else></div>

  </div>
</template>
<script>
export default {
  name: 'nature',
  props: ['nature'],
};
</script>
<style scoped>
.nature-icon {
  position: absolute;
    margin-left: -20px;
    padding-top: 3px;
}
.bm {
  color: #409eff;
}
.controller {
  color: #67c23a;
}
.firm {
  color: #909399;
}
</style>