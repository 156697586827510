<template>
  <tr
    :class="$store.getters.selectionMode ? 'table-row-selectionMode' : 'table-row'"
    @mouseover="checkboxVisible = true"
    @mouseleave="selected ? checkboxVisible = true : checkboxVisible = false"
  >

    <!-- Checkbox -->
    <td class="min-width-60 no-click">
      <div class="d-inline-flex">
        <transition name="fade">
          <b-form-checkbox
            v-show="checkboxVisible"
            :id="'checkbox-' + report.uuid"
            :name="'checkbox-' + report.uuid"
            v-model="selected"
            @change="checkboxChange"
            @click.stop
          >
          </b-form-checkbox>
        </transition>
      </div>
    </td>
    <!-- ID -->
    <td
      scope="row"
      class="min-width-120"
      @click="toggleExpanded(report.uuid)"
    >
      <Nature :nature="report.userNature" />
      {{ report.reportRef }}
    </td>
    <!-- Intervention type -->
    <!-- <td>
              <span v-if="report.interventionType != 'QUICK'"> {{ report.interventionType }}</span>

            </td> -->
    <!-- Priority / Result-->
    <td
      :v-show="$store.getters.columnVisibility.result == 'show'"
      @click="toggleExpanded(report.uuid)"
    >
      <Priority
        :priority="report.priority"
        v-if="report.priority != null"
      />
      <Quick
        :quickType="report.quickInteraction"
        v-if="report.quickInteraction != null"
      />
    </td>
    <!-- Status -->
    <td
      :v-show="$store.getters.columnVisibility.status == 'show'"
      @click="toggleExpanded(report.uuid)"
    >
      <Status
        :status="report.status"
        :target="'table'"
        class="margin-top-4"
      />
    </td>
    <!--  -->
    <td @click="toggleExpanded(report.uuid)"><mark
        class="wiRef txt-article-di"
        v-if="wiData.wiRef != null"
        v-b-tooltip.hover.bottom.html
        :title="formattedWITooltip"
        @click.stop="openModalWI(report.uuid)"
      >
        {{ wiData.wiRef }}
      </mark></td>
    <!--  -->
    <!-- <td> - - -</td> -->
    <!-- Building -->
    <td @click="toggleExpanded(report.uuid)">{{ this.localRef(report.buildingName, report.level, report.zone, report.serial, report.definition) }}</td>
    <!-- Level -->
    <!-- <td>{{ report.level }}</td> -->
    <!-- Zone -->
    <!-- <td>{{ report.zone }}</td> -->
    <!-- Serial -->
    <!-- <td>{{ report.serial }}</td> -->
    <!-- Definition -->
    <!-- <td>{{ report.definition }}</td> -->
    <!-- Programme -->
    <td @click="toggleExpanded(report.uuid)">
      <div
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.left.html="programComplete(report.familyName, report.activityName, report.specialtyName)"
        v-html="programOneLine(report.familyName, report.activityName, report.specialtyName)"
        class="td-program"
      ></div>
    </td>
    <!-- Description -->
    <td @click="toggleExpanded(report.uuid)">
      <div
        class="description-cell"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.left.html="descriptionComplete({ description: report.description, remark: report.remarkLabels, standard: report.standard, correctiveAction: report.standard })"
        v-html="descriptionOneLine({ description: report.description, remark: report.remarkLabels, standard: report.standard, correctiveAction: report.correctiveAction })"
      ></div>
    </td>

    <!-- Intervention date -->
    <td @click="toggleExpanded(report.uuid)">{{ report.interventionDate }}</td>
    <!-- By -->
    <td @click="toggleExpanded(report.uuid)">{{ report.user.acronym4digits }}</td>
    <!-- Entity -->
    <td @click="toggleExpanded(report.uuid)">{{ report.entityName }}</td>

  </tr>
</template>
<script>
import axiosResource from '@/utils/axiosResource';

import Priority from './Priority.vue';
import { mapGetters } from 'vuex';
import Status from '@/components/Explore/SharedExploreComponents/Status.vue';
import Quick from './Quick.vue';
import Nature from './Nature.vue';
import { localRefMixin } from '../../../mixins/localRefMixin';
import moment from 'moment';

export default {
  name: 'table-row',
  props: ['report', 'toggleExpanded'],
  mixins: [localRefMixin],
  components: {
    Priority,
    Status,
    Quick,
    Nature,
  },
  data() {
    return {
      selected: this.report.isSelected || false, // Initialize with report.isSelected
      checkboxVisible: this.report.isSelected || false, // Make checkbox visible if selected
    };
  },
  computed: {
    ...mapGetters({
      getWI: 'getWI',
    }),
    wiData() {
      return this.getWI(this.report.uuid);
    },
    formattedWITooltip() {
      let dueDateText;

      if (this.wiData.wiDueDate && this.wiData.wiDueDate !== '0000-00-00') {
        dueDateText = moment(this.wiData.wiDueDate).format('DD-MM-YYYY'); // e.g., "Nov 14, 2024"
      } else {
        dueDateText = this.$tc('wi.noDueDate'); // Display "No Due Date" if date is invalid
      }

      return dueDateText;
    },
  },
  watch: {
    'report.isSelected': {
      immediate: true,
      handler(newVal) {
        this.selected = newVal;
        this.checkboxVisible = newVal; // Show checkbox if report is selected
      },
    },
  },
  methods: {
    async checkboxChange() {
      // Toggle `isSelected` for the individual report
      this.report.isSelected = !this.report.isSelected;

      // If all reports are manually selected, check the header checkbox
      if (this.$store.getters.reports.every((report) => report.isSelected)) {
        this.$store.commit('setAllSelected', true);
      }

      if (this.selected) {
        await this.addToSelection();
      } else {
        await this.removeFromSelection();
        // this.report.isSelected = false; // Ensure report reflects deselected state
      }
    },
    // Add report to the selection
    async addToSelection() {
      const reportUuids = [this.report.uuid];
      try {
        const response = await axiosResource.post(`/selection/temporary/`, {
          reportUuids,
        });

        if (response.data.success) {
          // Handle success: set the report as selected and increment count in store
          this.report.isSelected = true; // Ensure report reflects selected state

          // Dispatch the action to update the selected count in Vuex
          const selectedCount = response.data.selectedCount;
          this.$store.dispatch('updateSelectedCount', selectedCount);
        }
      } catch (error) {
        // Check if error is due to exceeding the max selection limit
        if (error.response?.data?.error === 'error.selection.exceeds') {
          const maxSelection = error.response?.data?.maxSelection || 999; // default to 999 if not provided
          // Display error message with max selection limit
          this.$toast.error(
            this.$t('error.selection.exceeds', { count: maxSelection }),
            {
              position: 'top-center',
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            }
          );

          // Ensure the report remains unselected if max limit exceeded
          this.report.isSelected = false;
          this.selected = false; // Synchronize with local checkbox state if needed
        } else {
          // Handle any other error with a generic message
          const errorMessage =
            error.response?.data?.message || 'error.unknownError';
          this.$toast.error(this.$t(errorMessage), {
            position: 'bottom-right',
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          });
        }
      }
    },
    // Remove report from the selection
    async removeFromSelection() {
      const reportUuids = [this.report.uuid];
      try {
        const response = await axiosResource.delete(`/selection/temporary/`, {
          data: { reportUuids }, // Wrap reportUuids in `data` for DELETE request
        });
        if (response.data.success) {
          // Handle success, e.g., check the checkbox
          this.report.isSelected = false; // Ensure report reflects selected state

          // Dispatch the action to update the selected count in Vuex
          const selectedCount = response.data.selectedCount;
          this.$store.dispatch('updateSelectedCount', selectedCount);
        }
      } catch (error) {
        this.selected = true;
        const errorMessage =
          error.response?.data?.message || 'error.unknownError';
        this.$toast.error(this.$t(errorMessage), {
          position: 'bottom-right',
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        });
      }
    },
    openModalWI(reportUuid) {
      // this.$store.commit('deleteSelection');

      // this.$store.commit('selectReport', reportUuid);
      // Get selected report's data
      // const reports = this.$store.state.reports;
      // const report = reports.reports.find(
      //   (report) => report.uuid === reportUuid
      // );
      // if (report) {
      //   this.$store.commit('selectReportData', report);
      // }
      this.$store.commit('setReportUuid', reportUuid);
      this.$bvModal.show('modal-wi');
    },
    programOneLine(family, activity, specialty) {
      if (specialty !== '') return specialty; // Check for true null, not string 'null'
      if (specialty === '' && activity !== '') return activity;
      if (activity === '' && family !== '') return family;
      if (family === '') return '';
    },
    programComplete(family, activity, specialty) {
      let html;
      if (family != '') html = family;
      if (activity != '') html += '<br>' + activity;
      if (specialty != '') html += '<br>' + specialty;
      return html;
    },
    descriptionOneLine(obj) {
      let html = '';
      if (obj.description == '') {
        html = obj.remark;
      } else {
        html = obj.description;
      }
      return html;
    },
    descriptionComplete(obj) {
      let html = '';
      for (const [key, value] of Object.entries(obj)) {
        if (value != '') {
          html +=
            '<b>' +
            this.$tc('report.' + key).toUpperCase() +
            '</b><br>\n' +
            value +
            '<br><br>\n';
        } else {
          html += '';
        }
      }
      return html;
    },
  },
};
</script>
<style scoped>

/* TODO: put these 2 followinf classes in css file, duplicate!!! */
.txt-article-di {
  color: #262626;
  line-height: 12px !important;
  font-size: 11px;
  font-weight: lighter !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.1em;
  margin-top: 4px;
}
.wiRef {
  cursor: pointer;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.table-row {
  background-color: #fff;
}
.table-row-selectionMode {
  background-color: #ecf5ff;
}

.td-program {
  max-width: 300px; 
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
}
</style>
